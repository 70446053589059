<template>
  <b-card no-body header-bg-variant="white" class="h-100">
    <template #header v-if="showHeader">
      <span class="d-flex justify-content-between align-items-center">
        <!--<b-form-input v-if="isTitleEdit" id="input-title" size="sm" placeholder="Enter your title"></b-form-input>-->

        <b-container fluid>
          <b-row>
            <b-col cols="8">
              <span v-if="!isTitleEdit" @click="onTitleClick">{{
                header
              }}</span>
              <b-input-group v-if="isTitleEdit" size="sm">
                <b-form-input placeholder="Enter your title" v-model="title" />
                <b-input-group-append>
                  <b-button
                    size="sm"
                    text="Save"
                    variant="dark"
                    @click="onTitleSave"
                  >
                    <font-awesome-icon :icon="'save'" />
                  </b-button>
                  <b-button
                    size="sm"
                    text="Cancel"
                    variant="dark"
                    @click="onTitleCancel"
                  >
                    <font-awesome-icon :icon="'ban'" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-container>

        <b-dropdown
          size="sm"
          variant="outline"
          :dropleft="true"
          no-flip
          no-caret
        >
          <template #button-content>
            <font-awesome-icon icon="cog" />
          </template>

          <b-dropdown-item-button @click="close()"
            >Close</b-dropdown-item-button
          >
          <b-dropdown-item-button v-if="isGrouped" @click="onUnGroup"
            >Ungroup</b-dropdown-item-button
          >

          <b-dropdown-divider v-if="activeWidgets.length > 0" />
          <b-dropdown-group
            id="dropdown-group"
            header="Group with..."
            v-if="activeWidgets.length > 0"
          >
            <b-dropdown-item-button
              v-for="(widget, index) in activeWidgets"
              :key="`w-${index}`"
              @click="onGroup(widget.name)"
              >{{ widget.title }}</b-dropdown-item-button
            >
          </b-dropdown-group>
        </b-dropdown>
      </span>
    </template>

    <b-card-body
      v-if="isLoading"
      class="p-2 d-flex justify-content-center align-items-center overflow-hidden "
    >
      <b-row>
        <b-col>
          <div class="text-center">
            <b-spinner style="color:lightgrey;" />
          </div>
        </b-col>
      </b-row>
    </b-card-body>

    <perfect-scrollbar :options="{ suppressScrollX: false }">
      <b-card-body
        v-show="!isLoading"
        class="p-2 d-flex justify-content-center align-items-center overflow-hidden "
      >
        <slot name="content" />
      </b-card-body>
    </perfect-scrollbar>
  </b-card>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: undefined
    },
    header: {
      type: String,
      default: "Widget header"
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    activeWidgets: {
      type: Array,
      default: function() {
        return [];
      }
    },
    groupedWidgets: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  components: {},

  data: function() {
    return {
      title: "",
      isTitleEdit: false
    };
  },
  computed: {
    isGrouped() {
      return this.groupedWidgets.length > 0;
    }
  },
  created() {
    this.title = this.header;
  },
  mounted() {},
  methods: {
    onTitleClick() {
      if (this.isGrouped) {
        this.isTitleEdit = true;
      }
    },
    onTitleSave() {
      this.isTitleEdit = false;

      this.$emit("title-update", {
        name: this.name,
        title: this.title
      });
    },
    onTitleCancel() {
      this.isTitleEdit = false;
    },

    onGroup(e) {
      this.$emit("group", {
        nameInitiator: this.name,
        nameTarget: e
      });
    },
    onUnGroup() {
      this.$emit("ungroup", {
        name: this.name
      });
    },
    close() {
      this.$emit("close", {
        name: this.name
      });
    }
  },
  watch: {
    isLoading() {}
  }
};
</script>

<style scoped>
.card-header {
  cursor: move;
}

.dropdown-header {
  background: white;
}
</style>
