var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "h-100",
    attrs: {
      "no-body": "",
      "header-bg-variant": "white"
    },
    scopedSlots: _vm._u([_vm.showHeader ? {
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "8"
          }
        }, [!_vm.isTitleEdit ? _c('span', {
          on: {
            "click": _vm.onTitleClick
          }
        }, [_vm._v(_vm._s(_vm.header))]) : _vm._e(), _vm.isTitleEdit ? _c('b-input-group', {
          attrs: {
            "size": "sm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Enter your title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "size": "sm",
            "text": "Save",
            "variant": "dark"
          },
          on: {
            "click": _vm.onTitleSave
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": 'save'
          }
        })], 1), _c('b-button', {
          attrs: {
            "size": "sm",
            "text": "Cancel",
            "variant": "dark"
          },
          on: {
            "click": _vm.onTitleCancel
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": 'ban'
          }
        })], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "outline",
            "dropleft": true,
            "no-flip": "",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('font-awesome-icon', {
                attrs: {
                  "icon": "cog"
                }
              })];
            },
            proxy: true
          }], null, false, 2065011657)
        }, [_c('b-dropdown-item-button', {
          on: {
            "click": function click($event) {
              return _vm.close();
            }
          }
        }, [_vm._v("Close")]), _vm.isGrouped ? _c('b-dropdown-item-button', {
          on: {
            "click": _vm.onUnGroup
          }
        }, [_vm._v("Ungroup")]) : _vm._e(), _vm.activeWidgets.length > 0 ? _c('b-dropdown-divider') : _vm._e(), _vm.activeWidgets.length > 0 ? _c('b-dropdown-group', {
          attrs: {
            "id": "dropdown-group",
            "header": "Group with..."
          }
        }, _vm._l(_vm.activeWidgets, function (widget, index) {
          return _c('b-dropdown-item-button', {
            key: "w-".concat(index),
            on: {
              "click": function click($event) {
                return _vm.onGroup(widget.name);
              }
            }
          }, [_vm._v(_vm._s(widget.title))]);
        }), 1) : _vm._e()], 1)], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_vm.isLoading ? _c('b-card-body', {
    staticClass: "p-2 d-flex justify-content-center align-items-center overflow-hidden"
  }, [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    staticStyle: {
      "color": "lightgrey"
    }
  })], 1)])], 1)], 1) : _vm._e(), _c('perfect-scrollbar', {
    attrs: {
      "options": {
        suppressScrollX: false
      }
    }
  }, [_c('b-card-body', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoading,
      expression: "!isLoading"
    }],
    staticClass: "p-2 d-flex justify-content-center align-items-center overflow-hidden"
  }, [_vm._t("content")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }