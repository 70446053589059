var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "list-unstyled p-2 w-100"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('b-media', {
      key: "lce-".concat(index),
      attrs: {
        "tag": "li"
      }
    }, [_c('div', {
      staticClass: "d-flex mb-2 w-100 justify-content-between"
    }, [_c('h5', {
      staticClass: "mb-1"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('small', [_vm._v(_vm._s(item.datetime))])]), _c('VueReadMoreSmooth', {
      attrs: {
        "lines": 10
      }
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(item.body)
      }
    })])], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }