var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_c('table-custom', {
    ref: "requests",
    attrs: {
      "name": "custom_table_vacation_requests",
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [props.row.Status === 'On Review' && !_vm.isLoading ? _c('button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.confirmItem(props.row.ID);
            }
          }
        }, [props.row.isLoadingApprove ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e(), !props.row.isLoadingApprove ? _c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        }) : _vm._e()], 1) : _vm._e(), props.row.Status === 'On Review' && !_vm.isLoading ? _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.declineItem(props.row.ID);
            }
          }
        }, [props.row.isLoadingDecline ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e(), !props.row.isLoadingDecline ? _c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        }) : _vm._e()], 1) : _vm._e()])]);
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }