var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [!_vm.isLoading ? _c('v-client-table', {
    ref: "dataTable",
    attrs: {
      "name": "client_table_reports_overdue",
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options,
      "columns": _vm.dataTable.columns
    },
    on: {
      "row-click": _vm.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "Actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('span', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.submitReport(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        }), _vm._v(" Submit ")], 1)])]);
      }
    }], null, false, 1774775391),
    model: {
      value: _vm.dataTable.dataSet,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "dataSet", $$v);
      },
      expression: "dataTable.dataSet"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }