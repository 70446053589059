export default [
  {
    x: 0,
    y: 0,
    w: 3,
    h: 4,
    name: "clock",
    icon: "clock",
    title: "Clock CST",
    description: "Current date and time in the Central Time Zone",
    component: "Clock",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 6,
    h: 9,
    name: "latestCompanyNewsGroup",
    icon: "list",
    title: "Latest company news (GM)",
    description: "Let you not miss important information",
    component: "LatestCompanyNewsGM",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 6,
    h: 9,
    name: "latestCompanyNewsInternal",
    icon: "list",
    title: "Latest company news (IM)",
    description: "Let you not miss important information",
    component: "LatestCompanyNewsIM",
    visible: true
  },

  {
    x: 0,
    y: 0,
    w: 6,
    h: 8,
    name: "upcomingEvents",
    icon: "calendar-alt",
    title: "Upcoming events",
    description:
      "Top 20 upcoming activities (over the past 3 months currently)",
    component: "UpcomingEvents",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 6,
    h: 8,
    name: "myRecentActivities",
    icon: "calendar-alt",
    title: "My recent activites",
    description: "Displays a list of your activities over the past 3 months",
    component: "MyRecentActivities",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 6,
    h: 8,
    name: "othersRecentActivities",
    icon: "calendar-alt",
    title: "Other's users recent activities",
    description:
      "Displays a list of activities of other users over the past 3 months",
    component: "OthersRecentActivities",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 3,
    h: 7,
    name: "upcomingHolidays",
    icon: "home",
    title: "Upcoming holidays",
    description: "Displays upcoming holidays",
    component: "UpcomingHolidays",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 11,
    h: 6,
    name: "recentVacationRequests",
    icon: "list",
    title: "Recent vacation requests",
    description: "Displays a list of recent vacation requests",
    component: "RecentVacationRequests",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 6,
    h: 6,
    name: "activitiesForReview",
    icon: "exclamation",
    title: "Activities for review",
    description: "Displays a list of activities which have to be reviewed",
    component: "ActivitiesForReview",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 8,
    h: 10,
    name: "reportsForReview",
    icon: "file-alt",
    title: "Reports for review",
    description: "Displays reports for review",
    component: "ReportsForReview",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 8,
    h: 10,
    name: "reportsOverdue",
    icon: "file-alt",
    title: "Reports overdue",
    description: "Displays overdue reports",
    component: "ReportsOverdue",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 8,
    h: 10,
    name: "reportsUpcoming",
    icon: "file-alt",
    title: "Reports upcoming",
    description: "Displays upcoming reports",
    component: "ReportsUpcoming",
    visible: true
  },

  {
    x: 0,
    y: 0,
    w: 6,
    h: 8,
    name: "projects",
    icon: "tasks",
    title: "Projects",
    description: "Displays your projects",
    component: "Projects",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 8,
    h: 10,
    name: "todayTasks",
    icon: "tasks",
    title: "Today Tasks",
    description: "Displays today tasks",
    component: "TodayTasks",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 8,
    h: 10,
    name: "upcomingTasks",
    icon: "tasks",
    title: "Upcoming Tasks",
    description: "Displays upcoming tasks",
    component: "UpcomingTasks",
    visible: true
  },
  {
    x: 0,
    y: 0,
    w: 9,
    h: 9,
    name: "recentTimeLogs",
    icon: "user-clock",
    title: "Recent time logs",
    description: "Displays your time logs for the current and previous weeks",
    component: "RecentTimeLogs",
    visible: true
  }
];
