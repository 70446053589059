<template>
  <div class="w-100">
    <table-custom
      ref="requests"
      :name="`custom_table_vacation_requests`"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-success btn-sm"
            @click="confirmItem(props.row.ID)"
            v-if="props.row.Status === 'On Review' && !isLoading"
          >
            <b-spinner v-if="props.row.isLoadingApprove" small type="grow" />
            <font-awesome-icon
              icon="check"
              v-if="!props.row.isLoadingApprove"
            />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="declineItem(props.row.ID)"
            v-if="props.row.Status === 'On Review' && !isLoading"
          >
            <b-spinner v-if="props.row.isLoadingDecline" small type="grow" />
            <font-awesome-icon icon="ban" v-if="!props.row.isLoadingDecline" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import Vue from "vue";

import TableCustom from "@/components/TableCustom";

export default {
  components: {
    TableCustom
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {
      isReviewer: true,
      dataTable: {
        loading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "User Name",
            "Request Date",
            "From",
            "To",
            "Available (h)",
            "Requested (h)",
            "Unpaid (h)",
            "Approved (h)",
            "Notes",
            "Status",
            "Updated By",
            "Updated"
          ],
          formatColumns: [
            {
              name: "Available (h)",
              style: {
                style: "decimal"
              }
            },
            {
              name: "Requested (h)",
              style: {
                style: "decimal"
              }
            },
            {
              name: "Approved (h)",
              style: {
                style: "decimal"
              }
            }
          ],
          footerHeadings: false,
          perPage: 5,
          disablePerPageDropdown: true,
          showCustomActions: true,
          skin: "table-sm table-striped table-bordered table-hover w-100"
        }
      }
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    onClose(e) {
      this.$emit("close", e);
    },
    getData: async function() {
      this.$emit("loading", {
        name: this.name,
        isLoading: true
      });

      let response = await this.$api.get("dashboard/vacation-requests");

      this.dataTable.dataSet = response;

      this.$emit("loading", {
        name: this.name,
        isLoading: false
      });
    },

    confirmItem: async function(id) {
      let self = this;

      let confirm = await this.$form.showConfirmation(
        "Vacation request will be approved. Do you want to proceed?"
      );

      if (!confirm) return;

      let rowIdx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let row = this.dataTable.dataSet[rowIdx];

      row.isLoadingApprove = true;
      Vue.set(this.dataTable.dataSet, rowIdx, row);

      this.$api
        .get(`timetracker/vacation-requests/${row.ID}/approve`)
        .then(response => {
          row.isLoadingApprove = false;

          Vue.set(self.dataTable.dataSet, rowIdx, row);

          self.getData();

          self.$form.makeToastInfo(response.message);
        })
        .catch(error => {
          row.isLoadingApprove = false;

          Vue.set(self.dataTable.dataSet, rowIdx, row);

          self.$form.makeToastError(error.message);
        });
    },
    declineItem: async function(id) {
      let self = this;

      let confirm = await this.$form.showConfirmation(
        "Vacation request will be declined. Do you want to proceed?"
      );

      if (!confirm) return;

      let rowIdx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let row = this.dataTable.dataSet[rowIdx];

      row.isLoadingDecline = true;

      Vue.set(this.dataTable.dataSet, rowIdx, row);

      this.$api
        .get(`timetracker/vacation-requests/${row.ID}/decline`)
        .then(response => {
          row.isLoadingDecline = false;
          Vue.set(self.dataTable.dataSet, rowIdx, row);

          self.getData();

          self.$form.makeToastInfo(response.message);
        })
        .catch(error => {
          row.isLoadingDecline = false;

          Vue.set(self.dataTable.dataSet, rowIdx, row);

          self.$form.makeToastError(error.message);
        });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
