<template>
  <div class="w-100">
    <v-client-table
      v-if="!isLoading"
      ref="dataTable"
      name="client_table_reports_overdue"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      :columns="dataTable.columns"
      v-model="dataTable.dataSet"
      @row-click="onRowClick"
    >
      <div slot="Actions" slot-scope="props">
        <div class="btn-group">
          <span class="btn btn-primary btn-sm" @click="submitReport(props.row)">
            <font-awesome-icon icon="pencil-alt" /> Submit
          </span>
        </div>
      </div>
    </v-client-table>
  </div>
</template>

<script>
import { MODULES } from "@/shared/constants";

import { mapState, mapActions } from "vuex";

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {
      isLoading: false,
      isReviewer: true,
      MODULES: MODULES,
      controls: {
        buttons: {
          set_reviewed: {
            id: "button:set_reviewed"
          }
        }
      },
      dataTable: {
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: true,
          filterable: [], //'ID', 'Category', 'Frequency', 'User Name', 'Period Start', 'Period End', 'Notifications', 'Last Notification'],
          perPage: 5,
          perPageValues: [],
          saveState: true,
          storage: "local",
          skin: "table-sm table-striped table-bordered table-hover w-100"
        },
        columns: [
          "ID",
          "Category",
          "Frequency",
          "User Name",
          "Period Start",
          "Period End",
          "Notifications",
          "Last Notification",
          "Actions"
        ],
        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  ...mapActions("profile", ["fetchProfile"]),
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    submitReport(payload) {
      this.onRowClick(payload);
    },
    onRowClick(payload) {
      this.$router.push({
        name: "Report submission",
        params: {
          action: "create",
          pDepartmentId: payload.row.department_id,
          pCategoryId: payload.row.category_id,
          pFrom: payload.row["Period Start"],
          pTo: payload.row["Period End"]
        }
      });
    },
    getData: async function() {
      this.isLoading = true;
      this.$emit("loading", {
        name: this.name,
        isLoading: true
      });

      let response = await this.$api.get("dashboard/reports-overdue");

      this.rawData = response;

      this.isLoading = false;
      this.$emit("loading", {
        name: this.name,
        isLoading: false
      });

      this.dataTable.dataSet = this.rawData;
    }
  },
  watch: {}
};
</script>

<style scoped></style>
