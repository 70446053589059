var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [!_vm.isLoading ? _c('v-client-table', {
    ref: "dataTable",
    attrs: {
      "name": "client_table_reports4review",
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options,
      "columns": _vm.dataTable.columns
    },
    scopedSlots: _vm._u([{
      key: "Actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('span', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewReport(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), props.row.Reviewed === 'No' ? _c('span', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editReport(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1) : _vm._e(), props.row.Reviewed === 'No' ? _c('span', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteReport(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1) : _vm._e(), _c('button', {
          staticClass: "btn btn-dark btn-sm",
          attrs: {
            "title": "Preview"
          },
          on: {
            "click": function click($event) {
              return _vm.viewContent(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "search"
          }
        })], 1), _c('button', {
          class: [props.row['FilesCount'] !== '0' ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleFiles(props.row['ID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "download"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_c('files-container', {
          ref: "files",
          attrs: {
            "module-id": _vm.$route.meta.module.id,
            "entity-id": props.row['ID']
          }
        })], 1);
      }
    }], null, false, 3879620675)
  }) : _vm._e(), _c('b-modal', {
    ref: "preview-report-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.previewModal.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('div', {
    staticClass: "preview-report",
    domProps: {
      "innerHTML": _vm._s(_vm.previewModal.htmlContent)
    }
  })])], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_vm.previewModal.isReviewer && !_vm.previewModal.Reviewed ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.setReportReviewed();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "signature"
    }
  }), _vm._v(" Mark as Reviewed ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }