var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('payroll', {
    attrs: {
      "is-widget": true,
      "run": true,
      "start-date": _vm.startDate,
      "end-date": _vm.endDate
    },
    on: {
      "load": _vm.onPayrollLoad
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }