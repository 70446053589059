<template>
  <payroll
    :is-widget="true"
    :run="true"
    :start-date="startDate"
    :end-date="endDate"
    @load="onPayrollLoad"
  />
</template>

<script>
import Payroll from "@/views/Payroll/Payroll.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    Payroll
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    startDate() {
      return this.$nowClient
        .clone()
        .subtract(1, "week")
        .format("YYYY-MM-DD");
    },
    endDate() {
      return this.$nowClient.format("YYYY-MM-DD");
    }
  },
  created() {},
  mounted() {
    this.$emit("loading", { name: this.name, isLoading: true });
  },
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    onClose(e) {
      this.$emit("close", e);
    },
    onPayrollLoad() {
      this.$emit("loading", { name: this.name, isLoading: false });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
