<template>
  <ul class="list-unstyled p-2 w-100">
    <b-media tag="li" v-for="(item, index) in items" :key="`lce-${index}`">
      <div class="d-flex mb-2 w-100 justify-content-between">
        <h5 class="mb-1">
          {{ item.title }}
        </h5>
        <small>{{ item.datetime }}</small>
      </div>

      <VueReadMoreSmooth :lines="10">
        <div v-html="item.body" />
      </VueReadMoreSmooth>
    </b-media>
  </ul>
</template>

<script>
import VueReadMoreSmooth from "vue-read-more-smooth";

export default {
  components: {
    VueReadMoreSmooth
  },
  props: {
    name: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {
      items: []
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    onClose(e) {
      this.$emit("close", e);
    },
    getData: async function() {
      this.$emit("loading", { name: this.name, isLoading: true });

      let response = await this.$api.get("dashboard/company-news-internal");

      this.items = [];

      response.forEach(item => {
        this.items.push({
          datetime: item.created,
          title: item.title,
          body: item.introtext,
          url: "#"
        });
      });

      this.$emit("loading", { name: this.name, isLoading: false });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
