<template>
  <b-container fluid>
    <v-client-table
      v-if="!isLoading"
      ref="dataTable"
      name="client_table_activities4review"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      :columns="dataTable.columns"
      style="width: 100%"
    >
      <div slot="Actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-primary btn-sm"
            @click="viewActivity(props.row.ID)"
          >
            <font-awesome-icon icon="eye" />
          </button>
          <button
            class="btn btn-success btn-sm"
            @click="editActivity(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteActivity(props.row.ID)"
          >
            <font-awesome-icon icon="trash" />
          </button>
          <button
            class="btn btn-dark btn-sm"
            @click="showContentModal(props.row.ID)"
            title="Preview"
          >
            <font-awesome-icon icon="search" />
          </button>
        </div>
      </div>
    </v-client-table>
    <b-modal
      ref="preview-activity-modal"
      centered
      hide-footer
      size="lg"
      :title="previewModal.title"
    >
      <b-card>
        <b-card-body>
          <b-row>
            <b-col lg="3">
              <strong>User: </strong> {{ previewModal.userName }}
            </b-col>
            <b-col lg="3">
              <strong>Category: </strong> {{ previewModal.category }}
            </b-col>
            <b-col lg="6">
              <strong>Account: </strong> {{ previewModal.account }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3">
              <strong>Date: </strong> {{ previewModal.activityDate }}
            </b-col>
            <b-col lg="3">
              <strong>Time start: </strong> {{ previewModal.timeStart }}
            </b-col>
            <b-col lg="3">
              <strong>Time end: </strong> {{ previewModal.timeEnd }}
            </b-col>
          </b-row>
          <b-row />
          <b-row>
            <b-col>
              <strong>Subject: </strong> {{ previewModal.subject }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Description: </strong>
              <p>{{ previewModal.body }}</p>
            </b-col>
          </b-row>

          <hr />
          <b-button
            size="sm"
            v-b-toggle.collapse-2
            class="m-1"
            variant="outline-dark"
          >
            <font-awesome-icon icon="arrow-down" /> Tastings
          </b-button>

          <b-collapse id="collapse-2">
            <hr />
            <b-row>
              <b-col lg="3">
                <strong>Visitors: </strong>{{ previewModal.Visitors }}
              </b-col>
              <b-col lg="3">
                <strong>Expenses: </strong>{{ previewModal.Expenses }}
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="3">
                <strong>White Whisky: </strong>{{ previewModal.WhiteWhisky }}
              </b-col>
              <b-col cols="3">
                <strong>Whisky: </strong>{{ previewModal.Whisky }}
              </b-col>
              <b-col cols="3">
                <strong>Gin: </strong>{{ previewModal.Gin }}
              </b-col>
              <b-col cols="3">
                <strong>Vodka: </strong>{{ previewModal.Vodka }}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <strong>Liqueurs: </strong>{{ previewModal.Liqueurs }}
              </b-col>
              <b-col cols="3">
                <strong>Rum: </strong>{{ previewModal.Rum }}
              </b-col>
              <b-col cols="3">
                <strong>GinGiftPack: </strong>{{ previewModal.GinGiftPack }}
              </b-col>
              <b-col cols="3">
                <strong>WhiskeyGiftPack: </strong
                >{{ previewModal.WhiskeyGiftPack }}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <strong>Other: </strong>{{ previewModal.Other }}
              </b-col>
            </b-row>
          </b-collapse>
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          v-if="
            isReviewer &&
              !previewModal.Reviewed &&
              $permitted(controls.buttons.set_reviewed.id).visible
          "
          variant="warning"
          class="m-1"
          @click="setReviewedModal()"
        >
          <font-awesome-icon icon="signature" /> Mark as Reviewed
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
//import moment from "moment";

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {
      isLoading: false,
      isReviewer: true,
      controls: {
        buttons: {
          set_reviewed: {
            id: "button:set_reviewed"
          }
        }
      },

      dataTable: {
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: false,
          filterable: [],
          perPage: 10,
          perPageValues: [],
          saveState: true,
          storage: "local",
          skin: "table-sm table-striped table-bordered table-hover w-100"
        },
        columns: [
          "Activity Date",
          "Category",
          "Account Name",
          "City",
          "Subject",
          "Actions"
        ],
        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0,
        view: 1
      },
      previewModal: {
        ID: "",
        Reviewed: false,
        title: "",
        userName: "",
        category: "",
        account: "",
        activityDate: "",
        timeStart: "",
        timeEnd: "",
        subject: "",
        body: "",
        Visitors: "",
        Expenses: "",
        WhiteWhisky: "",
        Whisky: "",
        Gin: "",
        Vodka: "",
        Liqueurs: "",
        GinGiftPack: "",
        WhiskeyGiftPack: "",
        Other: ""
      },
      setReviewedModal() {
        this.$api
          .put(`activities/${this.previewModal.ID}/reviewed`)
          .then(response => {
            this.previewModal.Reviewed = true;

            this.$form.msgBoxOk(response.message);
          })
          .catch(error => {
            console.log(error);

            this.$form.msgBoxOk(error.message);
          });
      },
      closeModal: function() {
        this.$refs["preview-activity-modal"].hide();
      }
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.drawTable();
  },
  methods: {
    onClose(e) {
      this.$emit("close", e);
    },
    drawTable: async function() {
      this.isLoading = true;
      this.$emit("loading", {
        name: this.name,
        isLoading: true
      });

      let response = await this.$api.get("dashboard/activities-for-review");

      this.dataTable.dataSet = response;

      this.isLoading = false;
      this.$emit("loading", {
        name: this.name,
        isLoading: false
      });
    },

    viewActivity: function(id) {
      this.$router.push({
        name: "Activity submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editActivity: function(id) {
      this.$router.push({
        name: "Activity submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteActivity: async function(id) {
      let activity = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Activity #${activity.ID} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`activities/${activity.ID}`)
        .then(() => {
          self.$form.makeToastInfo("Activity deleted");

          self.drawdataTable();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.data.message);
        });
    },
    showContentModal(id) {
      let activity = this.dataTable.dataSet.find(item => item.ID === id);

      if (!activity) return;

      this.previewModal.title = activity["Subject"];

      this.previewModal.ID = activity.ID;

      this.previewModal.title = `Activity #${activity.ID}`;
      this.previewModal.userName = activity["Submitter"];
      this.previewModal.category = activity["Category"];
      this.previewModal.account = activity["Account Name"];
      this.previewModal.activityDate = activity["Activity Date"];
      this.previewModal.timeStart = activity["Start Time"];
      this.previewModal.timeEnd = activity["End Time"];
      this.previewModal.subject = activity["Subject"];
      this.previewModal.body = activity["Content"];

      this.previewModal.Visitors = activity["Visitors"];
      this.previewModal.Expenses = activity["Expenses"];
      this.previewModal.WhiteWhisky = activity["WhiteWhisky"];
      this.previewModal.Whisky = activity["Whisky"];
      this.previewModal.Gin = activity["Gin"];
      this.previewModal.Rum = activity["Rum"];
      this.previewModal.Vodka = activity["Vodka"];
      this.previewModal.Liqueurs = activity["Liqueurs"];
      this.previewModal.GinGiftPack = activity["GinGiftPack"];
      this.previewModal.WhiskeyGiftPack = activity["WhiskeyGiftPack"];
      this.previewModal.Other = activity["Other"];
      this.previewModal.Reviewed = activity["Reviewed"] === "Yes";

      this.$refs["preview-activity-modal"].show();
    }
  },
  watch: {}
};
</script>

<style scoped></style>
