<template>
  <div class="w-100">
    <tasks-table
      ref="tasks"
      :rows-per-page="20"
      :autoload="false"
      :is-nested-table="true"
      :columns="dataTable.options.columns"
      @loaded="onTasksLoad"
      :filter-by-column="false"
    />
  </div>
</template>

<script>
import moment from "moment";

import TasksTable from "@/views/ProjectManagement/TasksTable.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    TasksTable
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {
      isLoading: false,
      dataTable: {
        loading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Task Name",
            "Start Date",
            "Due Date",
            "Status",
            "Priority",
            "Actions"
          ],
          footerHeadings: false,
          perPage: 5,
          disablePerPageDropdown: true
          //showCustomActions: true,
          //skin:"table-sm table-striped table-bordered table-hover w-100",
        }
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  ...mapActions("profile", ["fetchProfile"]),
  created() {},
  mounted() {
    //this.getData()

    this.$refs["tasks"].getData();
  },
  methods: {
    onClose(e) {
      this.$emit("close", e);
    },
    onTasksLoad() {
      if (!this.$refs["tasks"].rawData.length) return;

      this.$emit("loading", {
        name: this.name,
        isLoading: false
      });

      this.isLoading = false;

      let filteredData = this.$refs["tasks"].rawData;

      filteredData = filteredData.filter(
        t =>
          moment(t["Due Date"]).isSame(this.$nowClient.format("YYYY-MM-DD")) &&
          !["Completed"].includes(t["Status"])
      );

      this.$refs["tasks"].dataTable.dataSet = filteredData;
    }
    /*
        getData: async function (e) {

            this.isLoading = true
            this.$emit('loading', {
                name: this.name,
                isLoading: true
            })

            let url = "";

            let response = await this.$api.get('tasks');

            this.$emit('loading', {
                name: this.name,
                isLoading: false
            })
            this.isLoading = false
            this.dataTable.dataSet = response.filter(t => moment(t['Due Date']).isSame(this.$nowClient.format('YYYY-MM-DD')) && !['Completed'].includes(t['Status']));
        },
        */
  },
  watch: {}
};
</script>

<style scoped></style>
