var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoading ? _c('div', {
    staticClass: "text-center"
  }, [_c('h1', {}, [_vm._v(" " + _vm._s(_vm.dateNow) + " ")]), _c('h2', {}, [_vm._v(" " + _vm._s(_vm.timeNow) + " ")]), _c('h2', {}, [_vm._v("Week " + _vm._s(_vm.weekNow))])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }