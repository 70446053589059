var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "sidebar-widget",
    attrs: {
      "no-body": "",
      "header-bg-variant": "white"
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('b-row', {
    staticClass: "m-0 p-0"
  }, [_c('b-col', {
    staticClass: "sidebar-widget-icon",
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    staticClass: "action",
    attrs: {
      "icon": _vm.icon,
      "size": "2x"
    }
  })], 1), _c('b-col', {
    staticClass: "sidebar-widget-body text-left small"
  }, [_c('h6', [_vm._v(_vm._s(_vm.title))]), _c('p', [_vm._v(_vm._s(_vm.description))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }