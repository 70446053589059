var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.sidebar-db",
      modifiers: {
        "sidebar-db": true
      }
    }],
    staticClass: "show-sidebar"
  }), _c('grid-layout', {
    attrs: {
      "layout": _vm.dashboardContainer,
      "col-num": 12,
      "row-height": 40,
      "is-draggable": true,
      "is-resizable": true,
      "is-mirrored": false,
      "vertical-compact": true,
      "margin": [10, 10],
      "use-css-transforms": true,
      "responsive": true
    },
    on: {
      "update:layout": function updateLayout($event) {
        _vm.dashboardContainer = $event;
      },
      "layout-updated": _vm.layoutUpdatedEvent
    }
  }, _vm._l(_vm.dashboardContainer, function (item) {
    return _c('grid-item', {
      key: item.name,
      class: item.class,
      attrs: {
        "x": item.x,
        "y": item.y,
        "w": item.w,
        "h": item.h,
        "i": item.i,
        "drag-allow-from": ".card-header"
      }
    }, [_c('widget-container', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.childs && item.childs.length > 0,
        expression: "item.childs && item.childs.length > 0"
      }],
      attrs: {
        "header": item.title,
        "show-header": true,
        "name": item.name,
        "grouped-widgets": item.childs
      },
      on: {
        "ungroup": _vm.onWidgetUnGroup,
        "close": function close($event) {
          return _vm.onWidgetGroupClose(item);
        },
        "title-update": _vm.onWidgetTitleUpdate
      },
      scopedSlots: _vm._u([{
        key: "content",
        fn: function fn() {
          return [_c('b-tabs', {
            staticClass: "w-100",
            attrs: {
              "justified": ""
            }
          }, _vm._l(item.childs, function (child, index) {
            return _c('b-tab', {
              key: "ch-".concat(index),
              attrs: {
                "title": child.title
              }
            }, [_c('b-row', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: child.isLoading,
                expression: "child.isLoading"
              }]
            }, [_c('b-col', [_c('div', {
              staticClass: "text-center"
            }, [_c('b-spinner', {
              staticStyle: {
                "color": "lightgrey"
              }
            })], 1)])], 1), _c(child.component, {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: !child.isLoading,
                expression: "!child.isLoading"
              }],
              key: child.name,
              tag: "component",
              attrs: {
                "name": child.name
              },
              on: {
                "loading": _vm.onWidgetLoad
              }
            })], 1);
          }), 1)];
        },
        proxy: true
      }], null, true)
    }), _c('widget-container', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.childs,
        expression: "!item.childs"
      }],
      attrs: {
        "header": item.title,
        "show-header": true,
        "is-loading": item.isLoading,
        "name": item.name,
        "active-widgets": _vm.dashboardContainer.filter(function (w) {
          return w.name !== item.name;
        })
      },
      on: {
        "close": _vm.onWidgetClose,
        "group": _vm.onWidgetGroup
      },
      scopedSlots: _vm._u([{
        key: "content",
        fn: function fn() {
          return [_c(item.component, {
            key: item.name,
            tag: "component",
            attrs: {
              "name": item.name
            },
            on: {
              "loading": _vm.onWidgetLoad
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1), _c('b-sidebar', {
    attrs: {
      "id": "sidebar-db",
      "shadow": "",
      "right": "",
      "bg-variant": "white",
      "width": "300px",
      "body-class": "align-items-center sidebar-body"
    }
  }, [_c('b-container', [_c('b-row', {
    attrs: {
      "no-glutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-input', {
    ref: "input-search",
    attrs: {
      "placeholder": "type to search..."
    },
    on: {
      "keyup": _vm.onSearchKeyUp
    }
  })], 1)], 1), _c('hr'), _c('perfect-scrollbar', {
    attrs: {
      "options": {
        suppressScrollX: true
      }
    }
  }, [_c('b-row', {
    staticStyle: {
      "height": "calc(100vh - 130px)"
    }
  }, [_c('b-col', _vm._l(_vm.sidebarContainer.filter(function (i) {
    return i.visible === true;
  }), function (child, index) {
    return _c('sidebar-widget', {
      key: "wgs-".concat(index),
      attrs: {
        "icon": child.icon,
        "title": child.title,
        "description": child.description
      },
      on: {
        "click": function click($event) {
          return _vm.addWidget(child.name);
        }
      }
    });
  }), 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }