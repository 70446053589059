<template>
  <div class="w-100">
    <v-client-table
      v-if="!isLoading"
      ref="dataTable"
      name="client_table_reports4review"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      :columns="dataTable.columns"
    >
      <div slot="Actions" slot-scope="props">
        <div class="btn-group">
          <span
            class="btn btn-primary btn-sm"
            @click="viewReport(props.row.ID)"
          >
            <font-awesome-icon icon="eye"
          /></span>
          <span
            v-if="props.row.Reviewed === 'No'"
            class="btn btn-success btn-sm"
            @click="editReport(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt"
          /></span>
          <span
            v-if="props.row.Reviewed === 'No'"
            class="btn btn-danger btn-sm"
            @click="deleteReport(props.row.ID)"
          >
            <font-awesome-icon icon="trash"
          /></span>
          <button
            class="btn btn-dark btn-sm"
            @click="viewContent(props.row.ID)"
            title="Preview"
          >
            <font-awesome-icon icon="search" />
          </button>

          <button
            :class="[
              props.row['FilesCount'] !== '0'
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleFiles(props.row['ID'])"
          >
            <font-awesome-icon icon="download" />
          </button>
        </div>
      </div>
      <span slot-scope="props" slot="child_row">
        <files-container
          ref="files"
          :module-id="$route.meta.module.id"
          :entity-id="props.row['ID']"
        />
      </span>
    </v-client-table>

    <b-modal
      ref="preview-report-modal"
      centered
      hide-footer
      size="lg"
      :title="previewModal.title"
    >
      <b-card>
        <b-card-body>
          <div class="preview-report" v-html="previewModal.htmlContent" />
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          v-if="previewModal.isReviewer && !previewModal.Reviewed"
          variant="warning"
          class="m-1"
          @click="setReportReviewed()"
        >
          <font-awesome-icon icon="signature" /> Mark as Reviewed
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FilesContainer from "@/components/FilesContainer";

import { MODULES } from "@/shared/constants";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    FilesContainer
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {
      isLoading: false,
      isReviewer: true,
      MODULES: MODULES,
      controls: {
        buttons: {
          set_reviewed: {
            id: "button:set_reviewed"
          }
        }
      },
      dataTable: {
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: false,
          filterable: [],
          perPage: 5,
          perPageValues: [],
          saveState: true,
          storage: "local",
          skin: "table-sm table-striped table-bordered table-hover w-100"
        },
        columns: [
          "ID",
          "Report Name",
          "Report Date",
          "From",
          "To",
          "Frequency",
          "Submitter",
          "Responsible",
          "Reviewer",
          "Reviewed",
          "Actions"
        ],
        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      },
      previewModal: {
        ID: "",
        title: "",
        htmlContent: "",
        isReviewer: false,
        Reviewed: false
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  ...mapActions("profile", ["fetchProfile"]),
  created() {},
  mounted() {
    this.drawTable();
  },
  methods: {
    onClose(e) {
      this.$emit("close", e);
    },
    toggleFiles(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },
    drawTable: async function() {
      this.isLoading = true;
      this.$emit("loading", { name: this.name, isLoading: true });

      let response = await this.$api.get("dashboard/reports-for-review");

      this.dataTable.dataSet = response;

      this.isLoading = false;
      this.$emit("loading", { name: this.name, isLoading: false });
    },
    viewReport: function(id) {
      this.$router.push({
        name: "Report submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editReport: function(id) {
      this.$router.push({
        name: "Report submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteReport: async function(id) {
      let report = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `${report["Report Name"]} #${report.ID} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`users/reports/${report.ID}`)
        .then(() => {
          self.$form.makeToastInfo("Report deleted");

          self.drawTable();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.data.message);
        });
    },
    setReportReviewed() {
      let self = this;

      this.$api
        .put(`users/reports/${this.previewModal.ID}/reviewed`)
        .then(response => {
          self.previewModal.Reviewed = true;

          self.drawReportsTable();

          self.$form.msgBoxOk(response.message);
        })
        .catch(error => {
          self.$form.msgBoxOk(error.message);
        });
    },
    closeModal: function() {
      this.$refs["preview-report-modal"].hide();
    },
    viewContent(id) {
      let report = this.dataTable.dataSet.find(item => item.ID === id);

      if (!report) return;

      this.previewModal.ID = report["ID"];
      this.previewModal.title = report["Report Name"];
      this.previewModal.htmlContent = report["Content"];
      this.previewModal.Reviewed = report["Reviewed"] == "Yes";

      let rev = report["reviewers_id"] ? report["reviewers_id"].split(",") : [];

      this.previewModal.isReviewer = rev.includes(
        this.profile.data.id.toString()
      );

      this.$refs["preview-report-modal"].show();
    }
  },
  watch: {}
};
</script>

<style scoped></style>
