<template>
  <b-list-group class="w-100">
    <b-list-group-item v-for="(item, index) in items" :key="`lce-${index}`">
      <div class="d-flex mb-2 w-100 justify-content-between">
        <div>{{ item.name }}</div>
        <span>{{ item.date }}</span>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: {
    name: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {
      isLoading: false,
      items: []
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    onClose(e) {
      this.$emit("close", e);
    },
    getData: async function() {
      this.isLoading = true;
      this.$emit("loading", { name: this.name, isLoading: true });

      let response = await this.$api.get("dashboard/upcoming-holidays");

      this.items = response.map(d => ({
        date: moment(d.date).format("dddd, LL"),
        name: d.name
      }));

      this.$emit("loading", { name: this.name, isLoading: false });
      this.isLoading = false;
    }
  },
  watch: {}
};
</script>

<style scoped></style>
