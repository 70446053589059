var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_c('table-custom', {
    attrs: {
      "data": _vm.dataTable.dataSet,
      "name": "custom_table_projects",
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('span', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.previewProject(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "search"
          }
        })], 1)])]);
      }
    }])
  }), _c('b-modal', {
    ref: "project-preview-modal",
    attrs: {
      "scrollable": "",
      "size": "lg",
      "title": _vm.projectPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openProjectInNewWindow(_vm.projectPreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closeProjectPreview();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('ProjectSubmissionForm', {
    attrs: {
      "id": _vm.projectPreviewModal.id,
      "action": "preview"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }