import { render, staticRenderFns } from "./ActivitiesForReview.vue?vue&type=template&id=fb57895a&scoped=true"
import script from "./ActivitiesForReview.vue?vue&type=script&lang=js"
export * from "./ActivitiesForReview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb57895a",
  null
  
)

export default component.exports