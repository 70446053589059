<template>
  <div v-if="!isLoading" class="text-center">
    <h1 class>
      {{ dateNow }}
    </h1>
    <h2 class>
      {{ timeNow }}
    </h2>
    <h2 class>Week {{ weekNow }}</h2>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: {
    name: {
      type: String,
      default: undefined
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      isLoading: false,
      nowClient: undefined
    };
  },
  computed: {
    defaults() {
      return {};
    },
    dateNow() {
      return moment(this.nowClient).format("ddd, ll");
    },
    timeNow() {
      return moment(this.nowClient).format("LTS");
    },
    weekNow() {
      return moment(this.nowClient).format("ww");
    }
  },
  created() {
    //let timerID = setInterval(this.updateTime, 100);
    setInterval(this.updateTime, 100);
    this.updateTime();
  },
  mounted() {},
  methods: {
    onClose(e) {
      this.$emit("close", e);
    },
    onGroup(e) {
      this.$emit("group", e);
    },
    onUngroup(e) {
      this.$emit("ungroup", e);
    },
    updateTime() {
      this.nowClient = moment
        .tz(moment(), moment.tz.guess())
        .format("YYYY-MM-DD HH:mm:ss");
    }
  },
  watch: {}
};
</script>

<style scoped></style>
