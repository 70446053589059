<template>
  <div class="animated fadeIn">
    <dashboard />
  </div>
</template>

<script>
import Dashboard from "@/components/Dashboard";

export default {
  name: "Overview",
  components: {
    Dashboard
  },
  data: function() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped></style>
