<template>
  <div class="w-100">
    <table-custom
      :data="dataTable.dataSet"
      :name="`custom_table_projects`"
      :options="dataTable.options"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <span
            class="btn btn-primary btn-sm"
            @click="previewProject(props.row)"
          >
            <font-awesome-icon icon="search" />
          </span>
        </div>
      </div>
    </table-custom>

    <b-modal
      ref="project-preview-modal"
      scrollable
      size="lg"
      :title="projectPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh;">
        <ProjectSubmissionForm :id="projectPreviewModal.id" action="preview" />
      </b-container>
      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openProjectInNewWindow(projectPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>

          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeProjectPreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";
import ProjectSubmissionForm from "@/views/ProjectManagement/ProjectSubmissionForm";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    //      TasksTable,
    TableCustom,
    ProjectSubmissionForm
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {
      isLoading: false,
      projectPreviewModal: {
        id: "",
        title: ""
      },
      dataTable: {
        loading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Project Name",
            "Start Date",
            "Finish Date",
            "Progress",
            "Actions"
          ],
          formatColumns: [
            {
              name: "Progress",
              style: {
                style: "percent"
              }
            }
          ],

          footerHeadings: false,
          perPage: 5,
          disablePerPageDropdown: true,
          showCustomActions: true
          //skin:"table-sm table-striped table-bordered table-hover w-100",
        }
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  ...mapActions("profile", ["fetchProfile"]),
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    previewProject(e) {
      this.projectPreviewModal.id = e["ID"];
      this.projectPreviewModal.title = `Project ${e["Project Name"]}`;

      this.$refs["project-preview-modal"].show();
    },
    closeProjectPreview: function() {
      this.$refs["project-preview-modal"].hide();
    },
    openProjectInNewWindow(project_id) {
      let routeData = this.$router.resolve({
        name: "Project submission",
        params: {
          id: project_id,
          action: "view"
        }
      });
      window.open(routeData.href, "_blank");
    },
    onClose(e) {
      this.$emit("close", e);
    },
    onTasksLoad() {},
    getData: async function() {
      this.isLoading = true;
      this.$emit("loading", {
        name: this.name,
        isLoading: true
      });

      let response = await this.$api.get("projects");

      this.isLoading = false;
      this.$emit("loading", {
        name: this.name,
        isLoading: false
      });

      this.dataTable.dataSet = response.filter(
        p => parseFloat(p.Progress) < 100
      );

      this.dataTable.dataSet.forEach(p => (p.Progress = p.Progress / 100));
    }
  },
  watch: {}
};
</script>

<style scoped></style>
