var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-list-group', {
    staticClass: "w-100"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('b-list-group-item', {
      key: "lce-".concat(index)
    }, [_c('div', {
      staticClass: "d-flex mb-2 w-100 justify-content-between"
    }, [_c('div', [_vm._v(_vm._s(item.name))]), _c('span', [_vm._v(_vm._s(item.date))])])]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }