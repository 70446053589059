<template>
  <b-card
    class="sidebar-widget"
    no-body
    header-bg-variant="white"
    @click="onClick"
  >
    <b-row class="m-0 p-0">
      <b-col cols="4" class="sidebar-widget-icon">
        <font-awesome-icon class="action" :icon="icon" size="2x" />
      </b-col>
      <b-col class="sidebar-widget-body text-left small">
        <h6>{{ title }}</h6>
        <p>{{ description }}</p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    description: {
      type: String,
      default: undefined
    }
  },
  components: {},

  data: function() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    onClick() {
      this.$emit("click", {});
    }
  },
  watch: {}
};
</script>

<style scoped>
.sidebar-widget {
  cursor: pointer;
  overflow: hidden;
  height: 8em;
  margin: 1em;
}

.sidebar-widget-icon {
  background: #2f353a;
  color: white;
  display: inline-block;
  height: 8em;
  vertical-align: middle;
  line-height: 8em;
  text-align: center;
}

.sidebar-widget-body {
  padding-top: 5px;
}

.sidebar-widget:hover {
  border-color: #8e9396;
}
</style>
