var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_c('tasks-table', {
    ref: "tasks",
    attrs: {
      "rows-per-page": 20,
      "autoload": false,
      "is-nested-table": true,
      "columns": _vm.dataTable.options.columns,
      "filter-by-column": false
    },
    on: {
      "loaded": _vm.onTasksLoad
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }