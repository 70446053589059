<template>
  <b-container fluid>
    <div v-b-toggle.sidebar-db class="show-sidebar" />

    <grid-layout
      :layout.sync="dashboardContainer"
      :col-num="12"
      :row-height="40"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true"
      :responsive="true"
      @layout-updated="layoutUpdatedEvent"
    >
      <grid-item
        v-for="item in dashboardContainer"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.name"
        :class="item.class"
        drag-allow-from=".card-header"
      >
        <widget-container
          v-show="item.childs && item.childs.length > 0"
          :header="item.title"
          :show-header="true"
          :name="item.name"
          :grouped-widgets="item.childs"
          @ungroup="onWidgetUnGroup"
          @close="onWidgetGroupClose(item)"
          @title-update="onWidgetTitleUpdate"
        >
          <template #content>
            <b-tabs justified class="w-100">
              <b-tab
                :title="child.title"
                v-for="(child, index) in item.childs"
                :key="`ch-${index}`"
              >
                <b-row v-show="child.isLoading">
                  <b-col>
                    <div class="text-center">
                      <b-spinner style="color:lightgrey;" />
                    </div>
                  </b-col>
                </b-row>
                <component
                  v-show="!child.isLoading"
                  :is="child.component"
                  :key="child.name"
                  :name="child.name"
                  @loading="onWidgetLoad"
                />
              </b-tab>
            </b-tabs>
          </template>
        </widget-container>
        <!--
            <component v-if="!item.childs" :is="item.component" :key="item.name" :title="item.title" :name="item.name" @close="onWidgetClose" @group="onWidgetGroup" @ungroup="onWidgetUngroup"></component>
-->
        <widget-container
          v-show="!item.childs"
          :header="item.title"
          :show-header="true"
          :is-loading="item.isLoading"
          :name="item.name"
          @close="onWidgetClose"
          @group="onWidgetGroup"
          :active-widgets="dashboardContainer.filter(w => w.name !== item.name)"
        >
          <template #content>
            <component
              :is="item.component"
              :key="item.name"
              :name="item.name"
              @loading="onWidgetLoad"
            />
          </template>
        </widget-container>
      </grid-item>
    </grid-layout>

    <b-sidebar
      id="sidebar-db"
      shadow
      right
      bg-variant="white"
      width="300px"
      body-class="align-items-center sidebar-body"
    >
      <b-container>
        <b-row no-glutters>
          <b-col cols="12">
            <b-input
              ref="input-search"
              placeholder="type to search..."
              @keyup="onSearchKeyUp"
            />
          </b-col>
        </b-row>
        <hr />
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <b-row style="height:calc(100vh - 130px);">
            <b-col>
              <sidebar-widget
                v-for="(child, index) in sidebarContainer.filter(
                  i => i.visible === true
                )"
                :key="`wgs-${index}`"
                :icon="child.icon"
                :title="child.title"
                :description="child.description"
                @click="addWidget(child.name)"
              />
            </b-col>
          </b-row>
        </perfect-scrollbar>
      </b-container>
    </b-sidebar>
  </b-container>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import SidebarWidget from "./SidebarWidget";
import WidgetContainer from "./WidgetContainer";

import Clock from "./widgets/Clock";
import LatestCompanyNewsGM from "./widgets/LatestCompanyNewsGM";
import LatestCompanyNewsIM from "./widgets/LatestCompanyNewsIM";
import UpcomingEvents from "./widgets/UpcomingEvents";
import UpcomingHolidays from "./widgets/UpcomingHolidays";
import MyRecentActivities from "./widgets/MyRecentActivities";
import OthersRecentActivities from "./widgets/OthersRecentActivities";
import RecentVacationRequests from "./widgets/RecentVacationRequests";
import ActivitiesForReview from "./widgets/ActivitiesForReview";
import ReportsForReview from "./widgets/ReportsForReview";
import ReportsOverdue from "./widgets/ReportsOverdue";
import ReportsUpcoming from "./widgets/ReportsUpcoming";

import Projects from "./widgets/Projects";
import TodayTasks from "./widgets/TodayTasks";
import UpcomingTasks from "./widgets/UpcomingTasks";
import RecentTimeLogs from "./widgets/RecentTimeLogs";

import widgets from "./widgets";

import { mapState } from "vuex";

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    WidgetContainer,
    SidebarWidget,
    Clock,
    LatestCompanyNewsIM,
    LatestCompanyNewsGM,
    UpcomingEvents,
    MyRecentActivities,
    OthersRecentActivities,
    UpcomingHolidays,
    RecentVacationRequests,
    ActivitiesForReview,
    ReportsForReview,
    ReportsOverdue,
    ReportsUpcoming,
    Projects,
    TodayTasks,
    UpcomingTasks,
    RecentTimeLogs
  },
  props: {
    header: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      isLoading: false,
      isDragging: false,
      dashboardContainer: [],
      sidebarContainer: []
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  mounted: async function() {
    let data = await this.$api.get(
      `users/${this.profile.data.id}/dashboard/settings`
    );

    let parsed = false;
    let widget = {};
    let activeWidgets = [];

    try {
      parsed = JSON.parse(data);
    } catch (e) {
      console.log(e);
    }

    if (parsed)
      parsed.forEach(item => {
        if (item.childs) {
          let group = Object.assign({}, item);
          group.i = this.$helpers.uuidv4();
          group.x = item.x;
          group.y = item.y;
          group.w = item.w;
          group.h = item.h;

          group.childs = [];

          item.childs.forEach(c => {
            let child = Object.assign(
              {},
              widgets.find(i => i.name === c.name)
            );

            child.i = this.$helpers.uuidv4();
            child.x = c.x || child.x;
            child.y = c.y || child.y;
            child.w = c.w || child.w;
            child.h = c.h || child.h;
            child.isLoading = false;

            group.childs.push(child);

            activeWidgets.push(child.name);
          });

          this.dashboardContainer.push(group);
        } else {
          widget = Object.assign(
            {},
            widgets.find(i => i.name === item.name)
          );

          widget.i = this.$helpers.uuidv4();
          widget.x = item.x || widget.x;
          widget.y = item.y || widget.y;
          widget.w = item.w || widget.w;
          widget.h = item.h || widget.h;

          this.dashboardContainer.push(widget);

          activeWidgets.push(widget.name);
        }
      });

    //add all widgets except those which present in dashboard
    widgets.forEach(item => {
      if (!activeWidgets.find(i => i === item.name)) {
        item.i = this.$helpers.uuidv4();
        item.visible = true;

        //check permissions and add

        if (this.$permitted(item.name).visible) {
          this.sidebarContainer.push(item);
        }
      }
    });

    this.sortSidebar();
  },

  methods: {
    onWidgetTitleUpdate(e) {
      let group = this.dashboardContainer.find(i => i.name === e.name);
      group.title = e.title;
      this.saveDashboardSettings();
    },
    onSearchKeyUp() {
      let text = this.$refs["input-search"].vModelValue;

      if (text.trim() === "") {
        this.sidebarContainer.forEach(i => (i.visible = true));
      } else {
        this.sidebarContainer.forEach(
          i =>
            (i.visible =
              i.title.toLowerCase().includes(text.toLowerCase()) ||
              i.description.toLowerCase().includes(text.toLowerCase()))
        );

        //this.sidebarContainer.forEach(i => (i.visible = i.description.toLowerCase().includes(text.toLowerCase())));
      }
    },

    addWidget(_name) {
      //remove from sidebar
      this.sidebarContainer = this.sidebarContainer.filter(
        i => i.name !== _name
      );

      this.sortSidebar();

      //add to dasboard
      if (!this.dashboardContainer.find(i => i.name === _name)) {
        let widget = Object.assign(
          {},
          widgets.find(i => i.name === _name)
        );

        //widget.i = this.dashboardContainer.length

        this.dashboardContainer.push(widget);

        this.saveDashboardSettings();
      }
    },
    onWidgetGroupClose(e) {
      //remove from dashboard
      this.dashboardContainer = this.dashboardContainer.filter(
        i => i.name !== e.name
      );

      e.childs.forEach(child => {
        //add to sidebar
        let w = Object.assign(
          {},
          widgets.find(i => i.name === child.name)
        );

        this.sidebarContainer.push(w);
      });

      this.sortSidebar();

      this.saveDashboardSettings();
    },
    onWidgetClose(e) {
      //remove from dashboard
      this.dashboardContainer = this.dashboardContainer.filter(
        i => i.name !== e.name
      );

      //add to sidebar
      let w = Object.assign(
        {},
        widgets.find(i => i.name === e.name)
      );

      this.sidebarContainer.push(w);

      this.sortSidebar();

      this.saveDashboardSettings();
    },
    onWidgetGroup(e) {
      let itemInitiatior = this.dashboardContainer.find(
        i => i.name === e.nameInitiator
      );
      let itemTarget = this.dashboardContainer.find(
        i => i.name === e.nameTarget
      );
      let group = {};

      //if destination is a group
      if (itemTarget.childs) {
        group = itemTarget;
        group.childs.push(itemInitiatior);
        this.dashboardContainer = this.dashboardContainer.filter(
          i => itemInitiatior.name !== i.name
        );
      } else {
        //otherwise create new group
        group.i = this.$helpers.uuidv4();
        group.name = "group-" + this.$helpers.uuidv4();
        group.title = group.name;
        group.x = itemInitiatior.x;
        group.y = itemInitiatior.y;
        group.w = itemInitiatior.w;
        group.h = itemInitiatior.h;

        group.childs = [];
        group.childs.push(itemInitiatior);
        group.childs.push(itemTarget);
        this.dashboardContainer = this.dashboardContainer.filter(
          i => ![itemInitiatior.name, itemTarget.name].includes(i.name)
        );
        this.dashboardContainer.push(group);
      }

      this.saveDashboardSettings();
    },
    onWidgetUnGroup(e) {
      let group = this.dashboardContainer.find(i => i.name === e.name);

      group.childs.forEach(child => {
        this.dashboardContainer.push(child);
      });

      this.dashboardContainer = this.dashboardContainer.filter(
        i => group.name !== i.name
      );

      this.saveDashboardSettings();
    },

    onWidgetLoad(e) {
      let item = this.dashboardContainer.find(i => i.name === e.name);

      //if not found search in grouped widgets

      if (!item) {
        for (let group of this.dashboardContainer) {
          item = group.childs.find(c => c.name === e.name);

          item.isLoading = e.isLoading;

          if (item) break;
        }
      } else {
        item.isLoading = e.isLoading;
      }
    },

    sortSidebar() {
      this.sidebarContainer.sort(function(a, b) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    },
    layoutUpdatedEvent: function() {
      this.saveDashboardSettings();
    },
    saveDashboardSettings() {
      let data = [];
      this.dashboardContainer.forEach(widget => {
        let item = {
          x: widget.x,
          y: widget.y,
          w: widget.w,
          h: widget.h,
          name: widget.name
        };

        if (widget.childs) {
          item.title = widget.title;
          item.childs = [];

          widget.childs.forEach(c => {
            let childItem = {
              x: c.x,
              y: c.y,
              w: c.w,
              h: c.h,
              name: c.name
            };
            item.childs.push(childItem);
          });
        }

        data.push(item);
      });

      let payload = {
        data: JSON.stringify(data)
      };

      this.$api.put(
        `users/${this.profile.data.id}/dashboard/settings`,
        payload
      );
    }
  },
  watch: {}
};
</script>

<style>
/*
.show-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 30px;
    background: #23282c;
    color: #e4e7ea;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: 40px;
    height: 40px;
    cursor: pointer;
    float: right;
    z-index: 100;
    position: fixed;
    bottom: 25px;
    right: 25px
}
*/
.show-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;

  /*border-radius: 30px;*/
  background: #23282c;
  color: #e4e7ea;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: 50px;
  height: 50px;
  cursor: pointer;
  float: right;
  z-index: 100;
  position: fixed;
  bottom: 0px;
  right: 0px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12.5px;
  /*background-color: rgba(0, 0, 0, 0.2);*/
}

.vue-grid-item {
  border-radius: 5px;
  background: #fff;
  min-height: 10rem;
  min-width: 10rem;
}

.vue-grid-item.vue-grid-placeholder {
  border-radius: 5px;
  background: red;
  opacity: 0.1;
  min-height: 10rem;
  min-width: 10rem;
}
</style>
